import React, { useState, useEffect } from "react";
import "./Layout.scss";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/image/admin-nav-logo.png";
import logoSmall from "../assets/image/PharmaNex_small.png";
import home from "../assets/image/sidebar/home.svg";
import inventory from "../assets/image/sidebar/inventory.svg";
import sales from "../assets/image/sidebar/sales.svg";
import purchases from "../assets/image/sidebar/purchased.svg";
import report from "../assets/image/sidebar/report.svg";

// const suburl = `Hello`
const suburl = localStorage.getItem("suburl");
const menuItems = [
  { label: "Home", path: `/user/${suburl}/home`, icon: home },
  {
    label: "Inventory",
    path: `/user/${suburl}/product`,
    icon: inventory,
    subMenuItems: [
      { label: "Product", path: `/user/${suburl}/product` },
      { label: "Groups", path: `/user/${suburl}/group` },
      { label: "Adjustments", path: `/user/${suburl}/inventory-adjustment` },
    ],
  },
  {
    label: "Sales",
    path: `/user/${suburl}/customers`,
    icon: sales,
    subMenuItems: [
      { label: "Customers", path: `/user/${suburl}/customers` },
      { label: "Doctors", path: `/user/${suburl}/doctors` },
      { label: "Invoices", path: `/user/${suburl}/invoices` },
      { label: "Payments Received", path: `/user/${suburl}/payments-received` },
      { label: "Sales Return", path: `/user/${suburl}/sales-return` },
      { label: "Credit Notes", path: `/user/${suburl}/credit-notes` },
    ],
  },
  {
    label: "Purchases",
    path: `/user/${suburl}/purchases1`,
    icon: purchases,
    subMenuItems: [
      { label: "Vendors", path: `/user/${suburl}/vendor` },
      { label: "Purchase Orders", path: `/user/${suburl}/purchases-order` },
      { label: "Purchase Receive", path: `/user/${suburl}/purchases-recieve` },
      { label: "Purchase Bills", path: `/user/${suburl}/purchases-bills` },
      { label: "Purchase Made", path: `/user/${suburl}/purchases-made` },
      { label: "Credit Note", path: `/user/${suburl}/credit-note` },
    ],
  },
  {
    label: "Reports",
    path: `/user/${suburl}/reports1`,
    icon: report,
    subMenuItems: [
      { label: "Reports1", path: `/user/${suburl}/reports1` },
      { label: "Reports2", path: `/user/${suburl}/reports2` },
    ],
  },
];

const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  // for collapse

  const toggleClass = () => {
    let root = document.getElementById("root");
    root.classList.toggle("collapsed");
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const currentPath = location.pathname;
    const activeMenu = menuItems.find((menuItem) =>
      menuItem.subMenuItems
        ? menuItem.subMenuItems.some((subMenuItem) =>
            currentPath.startsWith(subMenuItem.path)
          )
        : currentPath.startsWith(menuItem.path)
    );
    if (activeMenu) {
      setOpenKeys([activeMenu.label]);
    }
  }, [location.pathname]);
  useEffect(() => {}, [location.pathname]);
  const isActive = (path) => {
    return location.pathname.includes(path) ? "active" : "";
  };

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevKeys) => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter((k) => k !== key);
      }
      return [...prevKeys, key];
    });
  };

  const navigateRoute = (path) => {
    navigate(path);
  };
  return (
    <div>
      <div>
        <Menu className="side-menu">
          <div className="brand-name-sidebar">
            {collapsed ? 
            <div
              className='small-logo text-center'
              onClick={toggleClass}
            >
              <img style={{height:'48px',width:'auto',marginBottom:'9.5px',marginTop:'4.5px'}} src={logoSmall} alt="" />
            </div>
            :
            <div
            className='brand-name text-center'
            onClick={toggleClass}
          >
            <img style={{height:'62px',width:'auto'}} src={logo} alt="" />
          </div>}
           
            {/* <div
              className={
                collapsed ? "brand-name  small-logo" : "brand-name default-logo"
              }
              onClick={toggleClass}
            >
              <img src={collapsed ? logoSmall : logo} alt="" />
            </div> */}
          </div>

          <span className="menu-items">
            {menuItems.map((menuItem, index) => (
              <React.Fragment key={index}>
                {menuItem.subMenuItems ? (
                  <SubMenu
                    transitionDuration={300}
                    icon={<img src={menuItem.icon} alt={``} title={menuItem.label} />}
                    label={menuItem.label}
                    open={openKeys.includes(menuItem.label)}
                    onOpenChange={() => handleSubMenuClick(menuItem.label)}
                  >
                    {menuItem.subMenuItems.map((subMenuItem, subIndex) => (
                      <MenuItem
                        key={subIndex}
                        // icon={<img src={menuItem.icon} alt={``} title={subMenuItem.label}/>}
                        className={isActive(subMenuItem.path)}
                        onClick={() => navigateRoute(subMenuItem.path)}
                      >
                        <Link to={subMenuItem.path}>
                          <span className={collapsed ? "d-block" : "d-none"}>
                            <img src={subMenuItem?.icon} alt="" titlr="Ajit" />
                          </span>
                          <span className={collapsed ? "d-none" : ""}>
                            {subMenuItem.label}
                          </span>
                        </Link>
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  //  for home only
                  <MenuItem
                    key={index}
                    className={isActive(menuItem.path)}
                    onClick={() => navigateRoute(menuItem.path)}
                    icon={<img src={menuItem.icon} alt={``} title={menuItem.label}/>}
                    label={menuItem.label}
                    transitionDuration={300}
                  >
                    <Link to={menuItem.path}>
                      {/* <span className={collapsed ? "d-block" : "d-none"}>
                        <img src= {menuItem?.icon} alt=""/>
                      </span> */}
                      <span className={collapsed ? "d-none" : ""}>
                        {menuItem.label}
                      </span>
                    </Link>
                  </MenuItem>
                )}
              </React.Fragment>
            ))}
          </span>
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
