import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./InputTable.scss";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useParams } from "react-router-dom";
import ErrorModal from "../ErrorModal/ErrorModal";

const InputTable = ({
  initialRows = [],
  columns = [],
  className = "",
  onChange,
  value = [],
  disabled = false,
  newRowgenerate = "",
  productTabelError,
  setIsTableError,
}) => {


  const param = useParams();
  const productID = param?.id;
  const [rows, setRows] = useState([]);
  const [errorCells, setErrorCells] = useState([]);
  const inputRefs = useRef([]);
  const [showError, setShowError] = useState(false);
  const date = new Date();
  const ThisYear = date.getFullYear().toString().slice(-2);
  const Thismonth = (date.getMonth() + 1).toString().padStart(2, "0");
  console.log("value", value);
  console.log("rows", rows);
  useEffect(() => {
    if (errorCells.length > 0) {
      setIsTableError(true);
    } else {
      setIsTableError(false);
    }
  }, [errorCells]);

  useEffect(() => {
    if (productTabelError && rows?.length > 0 && errorCells.length > 0) {
      const LastRowIndex = rows.length - 1;
      const LastRow = rows[LastRowIndex];

      // Determine if any cell in the second last row is empty or has a placeholder value
      const errors = columns
        .filter((col) => {
          const value = LastRow[col.name];
          // Check if the value is empty or a placeholder that should be considered an error
          return (
            (value === null ||
              value === "" ||
              value === "MM/YY" ||
              value === "0.00") &&
            [
              "quantity",
              "mrp",
              "purchase_rate",
              "batch_no",
              "mfg_date",
              "expiry_date",
            ].includes(col.name)
          );
        })
        .map((col) => ({
          index: LastRowIndex,
          field: col.name,
        }));

      // Update errorCells state based on the errors found
      setErrorCells((prevErrorCells) => [
        ...prevErrorCells.filter((error) => !(error.index === LastRowIndex)),
        ...errors,
      ]);
    }
  }, [productTabelError]);
  const addRow = () => {
    // Create an empty row based on column definitions
    const emptyRow = columns.reduce((acc, col) => {
      switch (col.name) {
        case "batch_no":
          acc[col.name] = null;
          break;
        case "quantity":
        case "free_quantity":
          acc[col.name] = "0";
          break;
        case "mfg_date":
        case "expiry_date":
          acc[col.name] = "MM/YY";
          break;
        case "mrp":
        case "trade_rate":
        case "purchase_rate":
        case "sale_rate":
          acc[col.name] = "0.00";
          break;
        case "product_batch_id":
          acc[col.name] = "";
          break;
        default:
          acc[col.name] = "";
      }
      return acc;
    }, {});

    const newRows = [...rows, emptyRow];
    setRows(newRows);

    // console.log('=============>',rows)
    // Check if the second last row is empty
    if (newRows.length > 1) {
      const secondLastRowIndex = newRows.length - 2;
      const secondLastRow = newRows[secondLastRowIndex];

      // Determine if any cell in the second last row is empty or has a placeholder value
      const errors = columns
        .filter((col) => {
          const value = secondLastRow[col.name];
          // Check if the value is empty or a placeholder that should be considered an error
          return (
            (value === null ||
              value === "" ||
              value === "MM/YY" ||
              value === "0.00") &&
            [
              "quantity",
              "mrp",
              "purchase_rate",
              "batch_no",
              "mfg_date",
              "expiry_date",
            ].includes(col.name)
          );
        })
        .map((col) => ({
          index: secondLastRowIndex,
          field: col.name,
        }));

      // Update errorCells state based on the errors found
      setErrorCells((prevErrorCells) => [
        ...prevErrorCells.filter(
          (error) => !(error.index === secondLastRowIndex)
        ),
        ...errors,
      ]);
    }

    // Notify parent component of the row addition
    if (onChange) {
      onChange(newRows);
    }

    // Focus on the first input of the newly added row
    setTimeout(() => {
      inputRefs.current[newRows.length - 1]?.[0]?.focus();
    }, 0);
  };

  const deleteRow = (index) => {
    if (rows.length > 1) {
      // Remove the row from the rows array
      const newRows = [...rows];
      newRows.splice(index, 1);

      // Remove references for the deleted row
      const newInputRefs = [...inputRefs.current];
      newInputRefs.splice(index, 1);
      inputRefs.current = newInputRefs;

      // Adjust errorCells for rows below the deleted row
      const newErrorCells = errorCells
        .filter((error) => error.index !== index) // Remove errors related to the deleted row
        .map((error) => ({
          ...error,
          index: error.index > index ? error.index - 1 : error.index, // Adjust indices
        }));

      // Update rows, errors, and notify parent component
      setRows(newRows);
      setErrorCells(newErrorCells);
      if (onChange) {
        onChange(newRows);
      }

      // Focus on the new last row's first cell
      setTimeout(() => {
        if (newRows.length > 0) {
          inputRefs.current[newRows.length - 1]?.[0]?.focus();
        }
      }, 0);
    } else {
      const newRows = [...rows];
      newRows.splice(index, 2);
      setRows(newRows);
      if (onChange) {
        onChange(newRows);
      }
      setErrorCells([]);
    }
  };

  // Handle dynamic addition of refs

  useEffect(() => {
    setRows(
      value.length > 0 ? value : initialRows.length > 0 ? initialRows : []
    );
  }, [value, initialRows]);

  const handleDateChange = (index, event, field) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length >= 2) {
      let month = value.slice(0, 2);
      const year = value.slice(2);

      if (month > "12") {
        month = "12";
      }
      if (value.length === 2) {
        value = `${month}`;
      } else {
        value = `${month}/${year}`;
      }
    }
    if (field === "expiry_date") {
      let currentRow = rows[index];
      let currentRowmfg_date = currentRow?.mfg_date;
      if (
        value === "" ||
        currentRowmfg_date?.split("/")?.[1] > value?.split("/")?.[1] ||
        value === ""
      ) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    if (field === "mfg_date") {
      if (
        value === "" ||
        value?.split("/")?.[1] > ThisYear ||
        (value?.split("/")?.[1] >= ThisYear &&
          value?.split("/")?.[0] > Thismonth)
      ) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }

    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const handleChangeDefault = (index, event, field) => {
    let { name, value } = event.target;
    let currentRowValue = rows[index];
    if (field === "free_quantity") {
      if (Number(currentRowValue?.quantity) < Number(value)) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    if (field === "mrp") {
      if (
        Number(currentRowValue?.purchase_rate) > Number(value) ||
        value == 0
      ) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    if (field === "quantity") {
      if (value == 0) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    if (field === "purchase_rate") {
      if (value == 0) {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    if (field === "batch_no") {
      if (value === "") {
        setErrorCells([...errorCells, { index, field }]);
      } else {
        setErrorCells(
          errorCells.filter(
            (cell) => !(cell.index === index && cell.field === field)
          )
        );
      }
    }
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  const handleKeyDown = (index, event) => {
    let { name, value } = event.target;
    if (name === "mfg_date" || name === "expiry_date") {
      handleDateChange(index, event, name);
    } else {
      handleChangeDefault(index, event, name);
    }

    if (event.key === "Enter") {
      const column = columns.find((col) => col.name === name);
      if (column && column.label === newRowgenerate) {
        addRow();
      }
    }
  };

  const getToolTipData = (name) => {
    switch (name) {
      case "batch_no":
        return "Batch No can not be empty.";
      case "quantity":
        return "Quantity can not be empty or 0";
      case "mfg_date":
        return `MFG date can not be greater than ${Thismonth}/${ThisYear}`;
      case "mrp":
        return "MRP can not be empty";
      case "purchase_rate":
        return "Purchase Rate can not be greater than MRP or be empty";
      case "free_quantity":
        return "Free Quantity can not be more than Quantity";
      case "expiry_date":
        return "EXP Date can not be less than MFG date";
      default:
        return "";
    }
  };

  const ErrorModalOpen = () => {
    setShowError(true);
  };
  const CloseErrorModal = () => {
    setShowError(false);
    setTimeout(() => {
      if (rows.length > 0) {
        inputRefs.current[rows.length - 1]?.[0]?.focus();
      }
    }, 0);
  };
  return (
    <>
      {errorCells && errorCells?.length > 0 && (
        <div className="d-flex justify-content-end  text-danger  mb-0">
          {" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={ErrorModalOpen}
          >
            Show all error
          </span>
        </div>
      )}
      <div className="text-center mt-0">
        <div>
          <table>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th className={`${className}`} key={col.name}>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "start",
                        alignItems: "center",
                      }}
                    >
                      <span>{col.label}</span>
                      <span style={{ color: "red" }}>
                        {[
                          "batch_no",
                          "quantity",
                          "mfg_date",
                          "mrp",
                          "purchase_rate",
                          "expiry_date",
                        ].includes(col.name)
                          ? "*"
                          : ""}
                      </span>
                      {[
                        "batch_no",
                        "quantity",
                        "mfg_date",
                        "expiry_date",
                        "mrp",
                        "purchase_rate",
                        "free_quantity",
                      ].includes(col.name) ? (
                        <i className="toolTip_custum fa fa-info-circle">
                          <span className="toolTip_custumtext">
                            {getToolTipData(col.name)}
                          </span>
                        </i>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  {columns.map((col, colIndex) => (
                    <td
                      key={col.name}
                      className={
                        errorCells.some(
                          (cell) =>
                            cell.index === index && cell.field === col.name
                        )
                          ? "error"
                          : ""
                      }
                    >
                      {col.type === "batch_no" ? (
                        <input
                          type="text"
                          name={col.name}
                          value={row[col.name]}
                          onChange={(e) =>
                            handleChangeDefault(index, e, col.name)
                          }
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          disabled={disabled}
                          className="table_input"
                          ref={(el) => {
                            if (!inputRefs.current[index]) {
                              inputRefs.current[index] = [];
                            }
                            inputRefs.current[index][colIndex] = el;
                          }}
                        // ref={(el) =>
                        //   (inputRefs.current[index] = [
                        //     ...(inputRefs.current[index] || []),
                        //     el,
                        //   ])
                        // }
                        // ref={handleRef(index, colIndex)}
                        />
                      ) : col.type === "date" ? (
                        <input
                          type="text"
                          name={col.name}
                          value={row[col.name]}
                          onChange={(e) => handleDateChange(index, e, col.name)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          placeholder="MM/YY"
                          maxLength="5"
                          disabled={disabled}
                          className="table_input"
                          // ref={handleRef(index, colIndex)}
                          // ref={(el) =>
                          //   (inputRefs.current[index] = [
                          //     ...(inputRefs.current[index] || []),
                          //     el,
                          //   ])
                          // }
                          ref={(el) => {
                            if (!inputRefs.current[index]) {
                              inputRefs.current[index] = [];
                            }
                            inputRefs.current[index][colIndex] = el;
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          name={col.name}
                          value={row[col.name]}
                          onChange={(e) =>
                            handleChangeDefault(index, e, col.name)
                          }
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          disabled={disabled}
                          className="table_input"
                          // ref={(el) =>
                          //   (inputRefs.current[index] = [
                          //     ...(inputRefs.current[index] || []),
                          //     el,
                          //   ])
                          // }
                          ref={(el) => {
                            if (!inputRefs.current[index]) {
                              inputRefs.current[index] = [];
                            }
                            inputRefs.current[index][colIndex] = el;
                          }}
                        // ref={handleRef(index, colIndex)}
                        />
                      )}
                    </td>
                  ))}
                  <td>
                    {!disabled && !row?.product_batch_id && (
                      <button
                        className="delete mt-0 mb-0 bg-transparent"
                        onClick={() => deleteRow(index)}
                        tabIndex="-1"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M3 6H5H21"
                            stroke="#FF0000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                            stroke="#FF0000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 11V17"
                            stroke="#FF0000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 11V17"
                            stroke="#FF0000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {!disabled && (
          <button
            className="bg-transparent text-dark add-new-row-button mt-2"
            onClick={addRow}
          >
            Add New Row
            <i
              className="fa fa-plus"
              style={{
                marginLeft: ".5rem",
                strokeWidth: ".1rem",
                color: "var(--brand-color)",
                WebkitTextStrokeColor: "#fff",
                WebkitTextStrokeWidth: "1px",
              }}
            />
          </button>
        )}
      </div>
      <div className="text-start">
        {showError && (
          <ErrorModal
            show={showError}
            isOpen={showError}
            onClose={CloseErrorModal}
            title="Error in Add product Table"
            error={errorCells}
          />
        )}
      </div>
    </>
  );
};

InputTable.propTypes = {
  initialRows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  newRowgenerate: PropTypes.string,
};

export default InputTable;
