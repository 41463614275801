import React, { useState, useRef, useEffect } from "react";
import search from "../../assets/image/search-status.svg";
import "./GlobalSearch.scss";
import { useDispatch } from "react-redux";
import { globalSearch } from "../../store/action/globalSearch";
import { useLocation, useNavigate } from "react-router-dom";

const GlobalSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log('Location', location?.pathname.includes('product'))
  const [activebar, setActivebar] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResultsDropdown, setShowResultsDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);
  const dispatch = useDispatch();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const suburl = localStorage.getItem("suburl");

  useEffect(() => {
    if (location?.pathname.includes("product")) {
      setActivebar("Product");
    } else if (location?.pathname.includes("group")) {
      setActivebar("Group");
    } else if (location?.pathname.includes("inventory-adjustment")) {
      setActivebar("adjustment");
    } else if (location?.pathname.includes("vendor")) {
      setActivebar("vendor");
    } else {
      setActivebar("Product");
    }
  }, [location?.pathname]);
  const categories = [
    { id: "Product", label: "Product" },
    { id: "ProductCode", label: "Product Code" },
    { id: "Group", label: "Group" },
    { id: "adjustment", label: "Adjustment" },
    { id: "vendor", label: "Vendor" },
  ];

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      setTimeout(() => {
        let param = "";
        if (activebar === "Product") {
          param = `filterValue=${value}&filterBy=product_name`;
          dispatch(globalSearch(param)).then((res) => {
            if (res?.success) {
              const data =
                res?.data?.map((ele) => ({
                  id: ele?.product_id,
                  label: ele?.product_name,
                })) || [];
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        } else if (activebar === "ProductCode") {
          param = `filterValue=${value}&filterBy=product_code`;
          dispatch(globalSearch(param)).then((res) => {
            if (res?.success) {
              const data =
                res?.data?.map((ele) => ({
                  id: ele?.product_id,
                  label: ele?.product_code,
                })) || [];
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        }
        if (activebar === "Group") {
          param = `filterValue=${value}&filterBy=group`;
          dispatch(globalSearch(param)).then((res) => {
            // console.log(res?.data)
            if (res?.success) {
              const data =
                res?.data?.map((ele) => ({
                  id: ele?.product_group_id,
                  label: ele?.name,
                })) || [];
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        }
        if (activebar === "adjustment") {
          param = `filterValue=${value}&filterBy=adjustment`;
          dispatch(globalSearch(param)).then((res) => {
            // console.log(res?.data)
            if (res?.success) {
              const data =
                res?.data?.map((ele) => ({
                  id: ele?.adjustment_id,
                  label: ele?.reference_number,
                })) || [];
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        }
        if (activebar === "vendor") {
          param = `filterValue=${value}&filterBy=vendor`;
          dispatch(globalSearch(param)).then((res) => {
            console.log(res?.data);
            if (res?.success) {
              const data =
                res?.data?.map((ele) => ({
                  id: ele?.vendor_id,
                  label: ele?.vendor_name,
                })) || [];
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        }
      }, 300)
    );

    const results = searchResults.filter((item) =>
      item?.label?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setShowResultsDropdown(results.length > 0);
    setHighlightedIndex(-1);
  };

  const handleSeachBar = (param) => {
    setSearchResults([]);
    setActivebar(param);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % searchResults.length;
        scrollIntoView(newIndex);
        return newIndex;
      });
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => {
        const newIndex =
          (prevIndex + searchResults.length - 1) % searchResults.length;
        scrollIntoView(newIndex);
        return newIndex;
      });
    } else if (event.key === "Enter") {
      if (highlightedIndex >= 0) {
        handleRouteTopage(searchResults[highlightedIndex]);
      }
    }
  };

  const scrollIntoView = (index) => {
    const resultsContainer = resultsRef.current;
    if (resultsContainer && resultsContainer.firstChild) {
      const itemHeight = resultsContainer.firstChild.clientHeight;
      const scrollTop = resultsContainer.scrollTop;
      const containerHeight = resultsContainer.clientHeight;
      const itemOffset = index * itemHeight;

      if (itemOffset < scrollTop) {
        resultsContainer.scrollTop = itemOffset;
      } else if (itemOffset + itemHeight > scrollTop + containerHeight) {
        resultsContainer.scrollTop = itemOffset + itemHeight - containerHeight;
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      resultsRef.current &&
      !resultsRef.current.contains(event.target)
    ) {
      setShowResultsDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleRouteTopage = (data) => {
    if (activebar === "Product" || activebar === "ProductCode") {
      navigate(`/user/${suburl}/product-create/${data?.id}`);
    } else if (activebar === "Group") {
      navigate(`/user/${suburl}/group-details/${data?.id}`);
    } else if (activebar === "adjustment") {
      navigate(`/user/${suburl}/inventory-adjustment-crete/${data?.id}`);
    } else if (activebar === "vendor") {
      navigate(`/user/${suburl}/vendor-create/${data?.id}`);
    }
    setSearchQuery("");
    setSearchResults([]);
    setShowResultsDropdown(false);
  };
  //   const handleClickSeach = () => {
  //     handleSearchChange()
  //   };
  return (
    <div className="input-group form-mobile-check left-search w-auto">
      <div className="input-group-prepend">
        <div className="input-group-text p-0">
          <div className="dropdown text-end">
            <a
              className="d-block link-dark text-decoration-none dropdown-toggle px-3"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={search}
                alt="search-icon"
                width="20"
                height="20"
                className="rounded-circle me-2"
              />
            </a>
            <ul
              className="dropdown-menu font-14"
              aria-labelledby="dropdownUser1"
            >
              {categories.map((category) => (
                <li key={category.id}>
                  <a
                    className={`dropdown-item ${
                      activebar === category.id ? "active" : ""
                    }`}
                    onClick={() => handleSeachBar(category.id)}
                  >
                    {category.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-auto">
        <input
          ref={inputRef}
          type="search"
          className="form-control"
          style={{
            minWidth: "250px",
            borderLeft: "0",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          }}
          placeholder={`Search in ${activebar}`}
          aria-label="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          onClick={handleSearchChange}
        />
        {searchResults.length > 0 && showResultsDropdown && (
          <div
            className="dropdown-menu show font-14 mt-2"
            ref={resultsRef}
            style={{
              width: "250px",
              position: "absolute",
              zIndex: 1000,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {searchResults?.map((result, index) => (
              <div
                key={result.id}
                className={`dropdown-item ${
                  index === highlightedIndex ? "active" : ""
                } mt-1`}
                onClick={() => handleRouteTopage(result)}
              >
                {result.label}
              </div>
            ))}
          </div>
        )}
        {/* {searchQuery.length > 0 && searchResults.length > 0 && showResultsDropdown && (
                    <div className="dropdown-menu show font-14 mt-2" ref={resultsRef} style={{ width: '250px', position: 'absolute', zIndex: 1000, maxHeight: "300px", overflowY: "auto" }}>
                        {searchResults?.map((result, index) => (
                            <div
                                key={result.id}
                                className={`dropdown-item ${index === highlightedIndex ? 'active' : ''} mt-1`}
                                onClick={() => handleRouteTopage(result)}
                            >
                                {result.label}
                            </div>
                        ))}
                    </div>
                )} */}
      </div>
    </div>
  );
};

export default GlobalSearch;
