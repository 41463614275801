// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  color: black;
  font-weight: normal;
  text-decoration: none;
  position: relative;
  padding-bottom: 0.3rem;
  font-size: 1.5rem;
  padding-right: 1.5rem;
}
.nav-link:first-child {
  padding-left: 0;
}

.nav-link.active {
  color: black;
  font-weight: bold;
  text-decoration: none;
}
.nav-link.active::after {
  content: "";
  display: block;
  margin: 0 0;
  left: 0;
  bottom: 0;
  width: 80%;
  border-bottom: 2px solid #3B58FF;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/vendors/NavigationTab.scss"],"names":[],"mappings":"AAgBA;EACI,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;AAfJ;AAgBI;EACE,eAAA;AAdN;;AAkBE;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;AAfJ;AAiBI;EACE,WAAA;EACA,cAAA;EACA,WAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;EACA,gCAAA;EACA,kBAAA;AAfN","sourcesContent":[" \n\n// .nav-link {\n//   color: black;\n//   font-weight: normal;\n//   text-decoration: none;\n//   padding-bottom: 0.3rem; \n// }\n\n \n// .nav-link.active {\n//     color: black;\n//     font-weight: bold;\n//     text-decoration: none;\n//     border-bottom: 2px solid #3B58FF;\n//   }\n.nav-link {\n    color: black;\n    font-weight: normal;\n    text-decoration: none;\n    position: relative; // Make sure the parent is positioned relative\n    padding-bottom: 0.3rem; // Add some padding for better visual spacing\n    font-size: 1.5rem;\n    padding-right: 1.5rem;\n    &:first-child{\n      padding-left: 0;\n    }\n  }\n  \n  .nav-link.active {\n    color: black;\n    font-weight: bold;\n    text-decoration: none; // Remove underline if not needed\n  \n    &::after {\n      content: '';\n      display: block;\n      margin: 0 0;\n      left: 0;\n      bottom: 0;\n      width: 80%; // Adjust the length of the border-bottom here\n      border-bottom: 2px solid #3B58FF; // Adjust the size and color as needed\n      border-radius:8px ;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
