import React, { useEffect, useState } from "react";
import InputField from "../../component/form/FormInput";
import { VendorTds } from "../../utils/dropsdown";
import InputSelect from "../../component/inputSelect/InputSelect";
import Modal from "../../component/modal/Modal";
import UploadDocuments from "./uploadDocument/index";
import { validateGSTNumber, validatePANCard } from "../../utils/utility";
import {
	createPaymentTerms,
	getPaymentTerms,
	getPaymentTermsMaster,
} from "../../store/action/vendor";
import { useDispatch } from "react-redux";
import AddPaymentTerms from "./AddPaymentTerms";

const OtherDetails = ({ error, disabled, setError, data, handleInputChange }) => {
	const [paymentTerms, setPaymentTerms] = useState([]);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);

	const [openPaymentTermsModal, setOpenPaymentTermsModal] = useState(false);

	const [addNewPaymentTerms, setAddNewPaymentTerms] = useState("");

	useEffect(() => {
		dispatch(getPaymentTermsMaster)
			.then((res) => {
				const data = res.data;
				if (data?.success) {
					dispatch(getPaymentTerms).then((termsRes) => {
						if (termsRes?.data?.success) {
							const termsResData = termsRes?.data?.data;
							if (termsResData?.length > 0) {
								const newPaymentTerms = [...data?.data, ...termsResData];
								const paymentData = newPaymentTerms.map((el) => {
									const { term_name, ...restPayment } = el;
									return { ...restPayment, value: term_name };
								});
								setPaymentTerms(paymentData);
							}
						}
					});
				}
			})
			.catch((err) => {
				console.error("Error fetching payment terms", err);
			});
	}, []);

	const handelPaymentTermsInputChange = (value) => {
		setAddNewPaymentTerms(value);
	};

	const handleSaveNewPaymentTerms = async () => {
		setOpenPaymentTermsModal(false);
		setAddNewPaymentTerms("");
		dispatch(createPaymentTerms(addNewPaymentTerms))
			.then((res) => {
				if (res?.success) {
					const { term_name, ...rest } = res?.data;

					setPaymentTerms((prev) => {
						return [...prev, { ...rest, value: term_name }];
					});
				}
			})
			.catch((err) => {
				console.log("error found in adding payment terms", err);
			});
	};

	return (
		<div>
			<div className="row mt-1">
				<div className="col">
					<InputField
						label="PAN"
						placeholder="Vendor PAN"
						type="text"
						value={data.pan}
						onChange={(value) => handleInputChange("pan", value)}
						disabled={disabled}
						error={error?.pan}
						// onBlur={() => {
						// 	if (!validatePANCard(data.pan)) {
						// 		const newError = { ...error, pan: "Pan card is not valid" };
						// 		setError(newError);
						// 	}
						// }}
					/>
				</div>
				<div className="col">
					<InputField
						label="GST"
						placeholder="Vendor GST"
						type="text"
						value={data.name}
						onChange={(value) => handleInputChange("gst", value)}
						disabled={disabled}
						error={error?.gst}
						// onBlur={() => {
						// 	if (!validateGSTNumber(data.gst)) {
						// 		const newError = { ...error, gst: "Gst number is not valid" };
						// 		setError(newError);
						// 	}
						// }}
					/>
				</div>
				<div className="col">
					<InputSelect
						options={[]}
						label="Currency"
						placeholder="Vendor Currency"
						returnInputValue={true}
						onChange={(value) => handleInputChange("currency", value)}
						value={"INR"}
						disabled={true}
						required={true}
						error={error?.currency}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<InputSelect
						options={paymentTerms || []}
						label="Payment Terms"
						returnInputValue={true}
						placeholder="Vendor Payment Terms"
						value={data.payment_terms}
						onAddNew={() => setOpenPaymentTermsModal(true)}
						onChange={(value) => handleInputChange("payment_terms", value)}
						disabled={disabled}
						error={error?.paymentTerm}
					/>
				</div>
				<div className="col">
					<InputSelect
						options={VendorTds || []}
						label="TDS"
						returnInputValue={true}
						placeholder="Vendor TDS"
						value={"TDS"}
						onChange={(value) => handleInputChange("tds", value)}
						disabled={true}
						error={error?.tds}
					/>
				</div>
				<div className="col position-relative">
					{/* <div onClick={() => setOpen(true)}>
            <span>
              <InputField
                label="Documents"
                placeholder="Vendor Document"
                type="text"
                onClick={() => setOpen(true)}
                value="Upload Document"
                disabled={disabled}
                error={error?.doc}
                className="cursor-pointer"
              />
              <i
                style={{
                  position: "absolute",
                  top: "3.5rem",
                  right: "2rem",
                  fontSize: "1.9rem",
                }}
                className="fa fa-upload"
              ></i>
            </span>
          </div> */}
				</div>
			</div>
			<div className="row">
				<div className="col-4">
					<InputField
						label="Website URL"
						placeholder="Vendor Website URL"
						type="text"
						value={data.website_url}
						onChange={(value) => handleInputChange("website_url", value)}
						disabled={disabled}
						error={error?.website_url}
					/>
				</div>
				<div className="col-4">
					<InputField
						label="Designation"
						placeholder="Vendor Designation"
						type="text"
						value={data.designation}
						onChange={(value) => handleInputChange("designation", value)}
						disabled={disabled}
						error={error?.designation}
					/>
				</div>
			</div>

			<div>
				{open && (
					<div>
						<Modal
							className="uploadFile-modal"
							isOpen={open}
							onClose={() => setOpen(false)}
							title="Upload Documents"
							cancelButton={true}
							actionButton={true}
						>
							<UploadDocuments isOpen={open} onCLose={() => setOpen(false)} />
						</Modal>
					</div>
				)}
				{openPaymentTermsModal && (
					<div>
						<Modal
							className="add-payment-terms-modal"
							isOpen={openPaymentTermsModal}
							onClose={() => setOpenPaymentTermsModal(false)}
							title="Payment Terms"
							cancelButton={true}
						>
							<AddPaymentTerms
								isOpen={openPaymentTermsModal}
								onCLose={() => setOpenPaymentTermsModal(false)}
								addNewPaymentTerms={addNewPaymentTerms}
								handelPaymentTermsInputChange={handelPaymentTermsInputChange}
								handleSaveNewPaymentTerms={handleSaveNewPaymentTerms}
							/>
						</Modal>
					</div>
				)}
			</div>
		</div>
	);
};

export default OtherDetails;
