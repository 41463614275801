import React, { useState } from "react";
import InputField from "../../component/form/FormInput";
import Button from "../../component/ui/Button";

const AddPaymentTerms = ({
	addNewPaymentTerms,
	handelPaymentTermsInputChange,
	handleSaveNewPaymentTerms,
	onCLose,
}) => {
	const [error, setError] = useState("");

	return (
		<div>
			<form className="add-productgroup-form">
				<InputField
					label="Payment Terms"
					placeholder=""
					type="text"
					name="Enter payment terms"
					autoFocus={true}
					onChange={(val) => handelPaymentTermsInputChange(val)}
					value={addNewPaymentTerms}
					error={error?.addNewPaymentTerms || error}
				/>
				<span className="">
					<Button
						type="button"
						className="btn-primary mt-4 px-5 w-auto "
						button="Done"
						onClick={handleSaveNewPaymentTerms}
					></Button>
				</span>
			</form>
		</div>
	);
};

export default AddPaymentTerms;
