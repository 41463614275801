import React, { useState } from 'react'
import InputField from '../../component/form/FormInput'

const Remark = ({setRemark,remark}) => {
    const formData =[];
    const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(false); // Add state for disabling inputs
  return (
    <div>
       <div className="">
          <InputField
            label="Remark"
            placeholder="Write Remark"
            type="text"
            value={remark}
            onChange={(value) => setRemark(value)}
            disabled={disabled}
            error={error?.name}
          />
        </div>
    </div>
  )
}
  export default Remark

