import React from "react";
import PropTypes from "prop-types";
import "./modal.scss";
import Button from "../ui/Button";

const Modal = ({
	isOpen,
	onClose,
	title,
	children,
	className,
	cancelButton,
	actionButton,
	handleSave,
	handleClose,
}) => {
	if (!isOpen) return null;

	return (
		<div
			className={`modal-backdrop ${className}`}
			onClick={() => {
				console.log("here called");
				onClose();
			}}
		>
			<div className="modal-content" onClick={(e) => e.stopPropagation()}>
				<div className="modal-header">
					<p>{title}</p>
					{/* <button className="close-button"  onClick={onClose}>&times; dsfhkjfhasjkfh</button> */}

					<div className="d-flex gap-4">
						{actionButton && (
							<Button
								type="button"
								className="btn btn-lg btn-primary px-4 add-pro"
								button="Save"
								onClick={(e) => {
									e.stopPropagation();
									handleSave();
									onClose();
								}}
							/>
						)}
						{cancelButton && (
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => {
									handleClose && handleClose();
									onClose();
								}}
							/>
						)}
					</div>
				</div>
				<div className="modal-body">{children}</div>
			</div>
		</div>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.node,
};

Modal.defaultProps = {
	title: "",
	children: null,
};

export default Modal;
