import React, { useState } from "react";
import "../Vendors.scss";
import ReadOnlyTable from "../../../component/InputTable/ReadOnlyTable";

const ContactPersonTable = ({ tableData, vendorId, setTableData, className }) => {
	function deleteRow(index) {
		if (vendorId) {
		}
		console.log(index);
		const updatedTableData = tableData.filter((_, i) => i !== index);
		setTableData(updatedTableData);
	}

	const columns = [
		{ name: "salutation", label: "Salutation", type: "text" },
		{ name: "first_name", label: "First Name", type: "text" },
		{ name: "last_name", label: "Last Name", type: "text" },
		{ name: "mobile", label: "Mobile", type: "text" },
		{ name: "work_phone", label: "Work Phone", type: "text" },
		{ name: "email", label: "Email", type: "text" },
	];

	const initialRows = [
		{
			salutation: "",
			first_name: "",
			last_name: "",
			mobile: "",
			work_phone: "",
			email: "",
		},
	];
	return (
		<div
			className={`add-vendor-contactTable add-product-inputTable ${className}`}
			style={{ marginTop: "0" }}
		>
			<ReadOnlyTable
				className="th-width"
				initialRows={initialRows}
				columns={columns}
				value={tableData}
				disabled={true}
				deleteRow={deleteRow}
			/>
		</div>
	);
};

export default ContactPersonTable;
