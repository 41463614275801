import axios from "axios";

import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";
// DISPATCH CASES

export const VENDOR_LIST_DONE = "VENDOR_LIST_DONE";
export const VENDOR_LIST_FAIL = "VENDOR_LIST_FAIL";

export const VENDOR_BANK_DETAILS_CREATE_DONE = "VENDOR_BANK_DETAILS_CREATE_DONE";
export const VENDOR_BANK_DETAILS_CREATE_FAIL = "VENDOR_BANK_DETAILS_CREATE_FAIL";

export const VENDOR_BANK_DETAILS_GET_DONE = "VENDOR_BANK_DETAILS_GET_DONE";
export const VENDOR_BANK_DETAILS_GET_FAIL = "VENDOR_BANK_DETAILS_GET_FAIL";

export const VENDOR_BANK_DETAILS_DELETE_DONE = "VENDOR_BANK_DETAILS_DELETE_DONE";
export const VENDOR_BANK_DETAILS_DELETE_FAIL = "VENDOR_BANK_DETAILS_DELETE_FAIL";

export const VENDOR_BANK_DETAILS_UPDATE_DONE = "VENDOR_BANK_DETAILS_UPDATE_DONE";
export const VENDOR_BANK_DETAILS_UPDATE_FAIL = "VENDOR_BANK_DETAILS_UPDATE_FAIL";

// CONTACT PERSON
export const VENDOR_CONTACT_PERSON_CREATE_DONE = "VENDOR_CONTACT_PERSON_CREATE_DONE";
export const VENDOR_CONTACT_PERSON_CREATE_FAIL = "VENDOR_BANK_DETAILS_CREATE_FAIL";

export const VENDOR_CONTACT_PERSON_GET_DONE = "VENDOR_CONTACT_PERSON_GET_DONE";
export const VENDOR_CONTACT_PERSON_GET_FAIL = "VENDOR_BANK_DETAILS_GET_FAIL";

export const VENDOR_CONTACT_PERSON_DELETE_DONE = "VENDOR_CONTACT_PERSON_DELETE_DONE";
export const VENDOR_CONTACT_PERSON_DELETE_FAIL = "VENDOR_BANK_DETAILS_DELETE_FAIL";

export const VENDOR_CONTACT_PERSON_UPDATE_DONE = "VENDOR_CONTACT_PERSON_UPDATE_DONE";
export const VENDOR_CONTACT_PERSON_UPDATE_FAIL = "VENDOR_CONTACT_PERSON_UPDATE_FAIL";

export const CHANGE_STATUS_DONE = "CHANGE_STATUS_DONE";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

// END POINTS
export const CREATE_VENDOR_API_URL = `${APP_API_URL}/vendor/create-vendor`;
export const GET_PAYMENT_TERMS_MASTER = `${APP_API_URL}/vendor/get-payment-terms-master`;
export const GET_VENDOR_LIST = `${APP_API_URL}/vendor/get-vendor`;

export const VENDOR_CONTACT_PERSON_CREATE_API_URL = `${APP_API_URL}/vendor/vendor-contact-person`;
export const VENDOR_BANK_DETAILS_CREATE_API_URL = `${APP_API_URL}/vendor/vendor-bank-details`;
export const VENDOR_PAYMET_TERM_CREATE_API_URL = `${APP_API_URL}/vendor/create-vendor`;
export const CHANGE_STATUS_API_URL = `${APP_API_URL}/vendor/update-status`;
export const CREATE_PAYMENT_TERMS_API_URL = `${APP_API_URL}/vendor/create-payment-terms`;
export const GET_PAYMENT_TERMS = `${APP_API_URL}/vendor/get-payment-terms`;

let token = getLocalStorageData();

const configFormData = {
	headers: {
		"Content-Type": "multipart/form-data",
		Authorization: `Bearer ${token?.token}`,
	},
};

const config = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token?.token}`,
	},
};

export const createVendor = (formData) => (dispatch) => {
	const API_URL = CREATE_VENDOR_API_URL;
	return axios
		.post(API_URL, formData, configFormData)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

// vendor list
export const vendor_list = (param) => {
	let API_URL = `${GET_VENDOR_LIST}?${param}`;
	return (dispatch) => {
		return axios
			.get(API_URL, config)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: VENDOR_LIST_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: VENDOR_LIST_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const getPaymentTermsMaster = () => {
	const API_URL = GET_PAYMENT_TERMS_MASTER;
	return axios.get(API_URL, config);
};

export const getPaymentTerms = () => {
	const API_URL = GET_PAYMENT_TERMS;
	return axios.get(API_URL, config);
};

export const change_status = (formBody) => {
	let API_URL = CHANGE_STATUS_API_URL;
	return (dispatch) => {
		return axios
			.put(API_URL, formBody, config)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: CHANGE_STATUS_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: CHANGE_STATUS_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

// contact Details Function
export const vendor_contact_create = (formBody, vendorId) => {
	let API_URL = `${VENDOR_CONTACT_PERSON_CREATE_API_URL}/${vendorId}`;
	return (dispatch) => {
		return axios
			.post(API_URL, formBody, config)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: VENDOR_CONTACT_PERSON_CREATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: VENDOR_CONTACT_PERSON_CREATE_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const create_Vendor_document = (formBody, vendorId) => {
	let API_URL = `${VENDOR_BANK_DETAILS_CREATE_API_URL}/${vendorId}`;
	return (dispatch) => {
		return axios
			.post(API_URL, formBody, config)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: VENDOR_CONTACT_PERSON_CREATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: VENDOR_CONTACT_PERSON_CREATE_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const createPaymentTerms = (payment) => {
	let API_URL = `${CREATE_PAYMENT_TERMS_API_URL}`;
	return (dispatch) => {
		return axios
			.post(API_URL, { term_name: payment }, config)
			.then((res) => {
				return res?.data;
			})
			.catch((err) => {
				return err;
			});
	};
};
