import React, { useRef, useEffect } from 'react';
import './table.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const Table = ({ row, coloum, className, onRowDataChange, onRowClick,action }) => {
    const gridRef = useRef(null);
    // function onSelectionChanged() {
    //     const selectedNodes = gridRef.current.api.getSelectedNodes();
    //     const selectedData = selectedNodes.map(node => node.data);
    //     // console.log('Selected Data:', selectedData);
    // }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                const focusedCell = gridRef.current.api.getFocusedCell();
                if (focusedCell) {
                    const params = {
                        ...focusedCell,
                        data: gridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex).data,
                        colDef: focusedCell.column.getColDef()
                    };
                    onCellClicked(params);
                }
            } else if (event.key === 'ArrowUp') {
                navigateToPreviousRow();
            } else if (event.key === 'ArrowDown') {
                navigateToNextRow();
            }
        };

        const navigateToPreviousRow = () => {
            const focusedCell = gridRef.current.api.getFocusedCell();
            if (focusedCell && focusedCell.rowIndex > 0) {
                const previousRowIndex = focusedCell.rowIndex - 1;
                gridRef.current.api.setFocusedCell(previousRowIndex, focusedCell.column.colId);
            }
        };

        const navigateToNextRow = () => {
            const focusedCell = gridRef.current.api.getFocusedCell();
            if (focusedCell && focusedCell.rowIndex < row.length - 1) {
                const nextRowIndex = focusedCell.rowIndex + 1;
                gridRef.current.api.setFocusedCell(nextRowIndex, focusedCell.column.colId);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onCellClicked = (params) => {
        // console.log('Cell Clicked:', params);
        // console.log('Row Data:', params.data);
        // console.log('Custom Props:', params.colDef.customProps); // If you have any custom props
        onRowClick(params?.data?.id);
    };

    // Handle selection change
    const onSelectionChanged = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        let id = [];
        selectedData.forEach((ele) => {
            id.push(ele.id);
        });
        action(id);
    };
    const onCellValueChanged = (params) => {
        // console.log('Data after change:', params.data);
        if (onRowDataChange) {
            onRowDataChange(params.data);
        }
    };

    const CheckboxRenderer = (props) => {
        const handleCheckboxChange = (event) => {
            const checked = event.target.checked;
            const updatedData = { ...props.data, select: checked };
            props.node.setData(updatedData);
        };

        return (
            <input
                type="checkbox"
                checked={props.value}
                onChange={handleCheckboxChange}
            />
        );
    };

    const onFirstDataRendered = () => {
        gridRef.current.api.setFocusedCell(0, coloum[0].field); // Focus the first cell in the first column
    };

    return (
        <div className='planDetail-body'>
            <div className="ag-theme-quartz" style={{ height: '200px' }}>
                <div className="ag-grid-table">
                    <AgGridReact
                        ref={gridRef}
                        rowData={row}
                        className={className}
                        columnDefs={coloum}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        onFirstDataRendered={onFirstDataRendered}
                        onSelectionChanged={onSelectionChanged}
                        onCellClicked={onCellClicked}
                        onCellValueChanged={onCellValueChanged}
                        frameworkComponents={{
                            checkboxRenderer: CheckboxRenderer,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Table;
