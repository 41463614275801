import React, { useDebugValue, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sortbydot from "../../assets/image/sort_by_group.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/ui/Button";
import { InventoryAdjustmentTableColoum } from "../inventory/adjustment/InventoryAdjustmentColumn";
import Table from "../../component/table/Table";
import Pagination from "../../component/pagination";
import { vendorColoum } from "./VendorsColoum";
import { change_status, vendor_list } from "../../store/action/vendor";
import { toast } from "react-toastify";
import { ExportTable } from "../../store/action/export/Export";

const Vendors = () => {
  const suburl = localStorage.getItem("suburl");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [vendorList, setVendorLiat] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bulkAction, setBulkAction] = useState([]);
  const [filterparam, setFilterparam] = useState("");
  const [sortParam, setSortParam] = useState("");
  const vendorallList = useSelector(
    (state) => state?.vendorReducer?.vendorList?.data
  );
  console.log("vendor===>", vendorallList);
  const [tableData, setTableData] = useState([]);

  const [headerDropDown, setHeaderDropDown] = useState("All Vendor");
  const [stateCondition, setStateCondition] = useState("");

  const navigate = useNavigate();

  const navigateRouter = (path) => {
    navigate(path);
  };
  const onRowClick = (id) => {
    if (id) {
      navigate(`/user/${suburl}/vendor-create/${id}`);
    }
  };

  const getAllVendor = (param, value) => {
    if (!param) {
      param = `page=${currentPage}&pageSize=20`;
    }
    setLoader(true);
    // setStateCondition(value)
    dispatch(vendor_list(param))
      .then((res) => {
        if (res?.success) {
          setVendorLiat(res?.data);
          setLoader(false);
        } else {
          setVendorLiat([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setVendorLiat([]);
        setLoader(false);
        // console.log(err)
      });
  };

  const FilterDatalist = (param, value, dropDownData) => {
    setFilterparam(param);
    setStateCondition(value);
    setHeaderDropDown(dropDownData);
    setSortParam("");
    setCurrentPage(1);
  };
  const sortDataList = (param) => {
    setSortParam(param);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllVendor();
  }, []);

  useEffect(() => {
    let data = [];
    vendorallList?.vendors?.map((ele) => {
      data?.push({
        id: ele.vendor_id,
        NAME: ele?.vendor_name,
        "VENDOR DISPLAY NAME": ele?.vendor_display_name,
        "WORK PHONE": ele?.phone_mobile,
        PAYABLE: "N/A",
      });
    });
    setTableData(data);
    // console.log(productList)
    let toatlPages = Math.ceil(vendorallList?.totalCount / 20);
    setTotalPages(toatlPages);
  }, [vendorallList]);

  useEffect(() => {
    let param = `${filterparam}${filterparam && "&"}${sortParam}${
      sortParam && "&"
    }page=${currentPage}&pageSize=20`;
    getAllVendor(param);
  }, [sortParam, filterparam, currentPage]);

  const markAsActive = () => {
    let formbody = {
      vendor_ids: bulkAction,
      status: 1,
    };
    dispatch(change_status(formbody)).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        getAllVendor();
        setBulkAction([]);
      } else {
        toast.error(res?.message);
      }
    });
  };
  const markAsinactive = () => {
    let formbody = {
      vendor_ids: bulkAction,
      status: 0,
    };
    dispatch(change_status(formbody)).then((res) => {
      if (res?.success) {
        toast.success(res?.message);
        getAllVendor();
        setBulkAction([]);
      } else {
        toast.error(res?.message);
      }
    });
  };
  const exportData = (param) => {
    ExportTable(param);
  };
  return (
    <div>
      {bulkAction && bulkAction.length > 0 ? (
        <div className="product-pg-header">
          <div className="dropdown text-end">
            <p className="d-block link-dark text-decoration-none">
              Bulk Actions
            </p>
          </div>
          <div className="add-product-button">
            <Button
              type="button"
              button="Print"
              className="mt-0 px-4 btn-gray"
              onClick={() => window.print()}
            />
            <Button
              type="button"
              button="Bulk Update"
              className="mt-0 px-4 btn-gray"
            />
            {stateCondition !== "active" && (
              <Button
                type="button"
                button="Mark as Active"
                className="mt-0 px-4 btn-gray"
                onClick={markAsActive}
              />
            )}
            {stateCondition !== "inactive" && (
              <Button
                type="button"
                button="Mark as Inactive"
                className="mt-0 px-4 btn-gray"
                onClick={markAsinactive}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="product-pg-header add-product">
          <div className="dropdown text-end">
            <a
              className="d-block link-dark text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p>{headerDropDown}</p>
            </a>
            <ul
              className="dropdown-menu font-14"
              aria-labelledby="dropdownUser1"
              style={{ minWidth: "240px" }}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist(
                      "orderBy=product_code&sortBy=asc",
                      "",
                      "All Products"
                    )
                  }
                >
                  All Vendor
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist(
                      "filterBy=status&filterValue=1",
                      "active",
                      "Acitive Vendor"
                    )
                  }
                >
                  Active Vendor
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist(
                      "filterBy=crm&filterValue=0",
                      "crm",
                      "CRM Vendor"
                    )
                  }
                >
                  CRM Vendor
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    FilterDatalist(
                      "filterBy=status&filterValue=0",
                      "inactive",
                      "Inactive Vendor"
                    )
                  }
                >
                  Inactive Vendor
                </a>
              </li>
            </ul>
          </div>

          <div className="add-product-button column-gap-0">
            <Button
              type="button"
              className="btn-primary mt-0 px-5"
              button="Create New Vendor"
              add
              onClick={() => navigateRouter(`/user/${suburl}/vendor-create`)}
            />
            <div className="dropdown text-end ms-3">
              <a
                href="#"
                className="d-block link-dark text-decoration-none"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={sortbydot} />
              </a>
              {/* date , reason, created_at , description,status,reference_number, */}
              <ul
                className="dropdown-menu font-14"
                aria-labelledby="dropdownUser1"
                style={{ minWidth: "240px" }}
              >
                <li>
                  <a className="dropdown-item sortBy">Sort By</a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=vendor_name&sortBy=asc`)
                    }
                  >
                    Name
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=vendor_display_name&sortBy=asc`)
                    }
                  >
                    Vendor display Name
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => sortDataList(`orderBy=reason&sortBy=asc`)}
                  >
                    Payables
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=created_at&sortBy=asc`)
                    }
                  >
                    Created Time
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=created_at&sortBy=asc`)
                    }
                  >
                    Last Modifyed Time
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=description&sortBy=asc`)
                    }
                  >
                    Import Vendors
                  </a>
                </li>

                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => exportData(`vendor`)}
                  >
                    Export Vendor
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=reference_number&sortBy=asc`)
                    }
                  >
                    Preferences
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      sortDataList(`orderBy=reference_number&sortBy=asc`)
                    }
                  >
                    Refresh
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", marginTop: "20%" }}
        >
          <span className="Page_loader" role="status" aria-hidden="true"></span>
        </div>
      ) : (
        <div className="productTable-body">
          <Table
            row={tableData}
            coloum={vendorColoum}
            className="productTable"
            onRowClick={onRowClick}
            action={setBulkAction}
          />
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default Vendors;
