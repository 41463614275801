import React, { useState } from "react";
import "./BankDetails.scss";
import plus from "../../../assets/image/plus.svg";
import Modal from "../../../component/modal/Modal";
import AddBankDetails from "../AddBankDetails";
import BankCard from "../BankCard";

const BankDetails = ({ data, error, setBankDetails, vendorId, reloadTable }) => {
	const [open, setOpen] = useState(false);

	const [bankIndex, setBankIndex] = useState(null);

	const openEditBankDetailsModal = (bankId) => {
		setBankIndex(bankId);
		setOpen(true);
	};

	const handleEditBankDetails = (editedBankDetails) => {
		console.log("editedbankDetails", editedBankDetails);
		const newBankDetails = data.map((bank, i) =>
			bankIndex === i ? { ...bank, ...editedBankDetails } : { ...bank }
		);

		setBankDetails(newBankDetails);
		setBankIndex(null);
		setOpen(false);
	};

	const selectedBank = bankIndex !== null ? data[bankIndex] : null;

	return (
		<div className="bankDetailsBody">
			<div className="headr">
				<p>Bank Accounts</p>
				<button
					type="button"
					class="w-100 btn btn-primary"
					style={{ paddingLeft: "9px", paddingRight: "9px" }}
					onClick={() => {
						setOpen(true);
					}}
				>
					<img src={plus} alt="" />
				</button>
			</div>

			{data?.length > 0 &&
				data.map((bank, i) => (
					<React.Fragment key={i}>
						<BankCard id={i} data={bank} openEditBankDetailsModal={openEditBankDetailsModal} />
					</React.Fragment>
				))}

			{/* modal */}
			<div>
				{open && (
					<div>
						<Modal
							className="uploadFile-modal"
							isOpen={open}
							onClose={() => {
								setOpen(false);
								if (selectedBank) setBankIndex(null);
							}}
							title="Add Bank Account"
							cancelButton={true}
							//   actionButton={true}
						>
							<AddBankDetails
								isEdit={!!selectedBank}
								data={data}
								selectedBank={selectedBank}
								isOpen={open}
								onClose={() => {
									setOpen(false);
								}}
								setBankDetails={setBankDetails}
								handleEditBankDetails={handleEditBankDetails}
								vendorId={vendorId}
								reloadTable={reloadTable}
							/>
						</Modal>
					</div>
				)}
			</div>
		</div>
	);
};
export default BankDetails;
