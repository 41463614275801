import React, { useState, useEffect, useRef } from "react";
import "./inputSelect.scss";

const InputSelect = ({
	label,
	options = [],
	placeholder,
	onAddNew,
	onChange,
	returnInputValue,
	required,
	value,
	disabled,
	autoFocus,
	infoIcon,
	name,
	propInputRef,
	propKeydown,
	onClick,
	error,
	isLocalValidationError,
	onfucusCapture,
	className,
}) => {
	const [inputValue, setInputValue] = useState(value || "");
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const dropdownRef = useRef(null);
	const selectedItemRef = useRef(null);
	const inputRef = useRef(null);
	const handleDropDown = () => {
		setIsDropdownVisible(true);
	};

	useEffect(() => {
		const filtered = options.filter((option) =>
			option?.value?.toLowerCase()?.includes(inputValue.toLowerCase())
		);
		setFilteredOptions(filtered || []);
		setSelectedIndex(-1);
	}, [inputValue, options]);

	useEffect(() => {
		if (!returnInputValue || !value) {
			const foundOption = options.find((option) => option?.key === value);
			if (foundOption) {
				setInputValue(foundOption.value);
			}
		}
	}, [returnInputValue, value, options]);

	useEffect(() => {
		if (autoFocus && inputRef.current) {
			inputRef.current.focus();
			handleDropDown();
		}
	}, [autoFocus]);

	useEffect(() => {
		if (selectedItemRef.current && dropdownRef.current) {
			const dropdown = dropdownRef.current;
			const selectedItem = selectedItemRef.current;
			const dropdownRect = dropdown.getBoundingClientRect();
			const selectedItemRect = selectedItem.getBoundingClientRect();

			if (selectedItemRect.bottom > dropdownRect.bottom) {
				dropdown.scrollTop += selectedItemRect.bottom - dropdownRect.bottom;
			} else if (selectedItemRect.top < dropdownRect.top) {
				dropdown.scrollTop -= dropdownRect.top - selectedItemRect.top;
			}
		}
	}, [selectedIndex]);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
		setIsDropdownVisible(true);
		if (onChange) {
			onChange(returnInputValue ? e.target.value : null);
			error = null;
		}
	};

	const handleOptionClick = (option) => {
		setInputValue(option.value);
		setIsDropdownVisible(false);
		if (onChange) {
			onChange(returnInputValue ? option.value : option.key);
		}
	};

	const handleBlur = () => {
		setIsDropdownVisible(false);
		setTimeout(() => {}, 200);
	};

	const handleKeyDown = (e) => {
		// if(propKeydown){
		//     propKeydown(e)
		// }
		if (isDropdownVisible) {
			if (e.key === "ArrowDown") {
				e.preventDefault();
				setSelectedIndex((prevIndex) => (prevIndex < filteredOptions.length ? prevIndex + 1 : 0));
			} else if (e.key === "ArrowUp") {
				e.preventDefault();
				setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredOptions.length));
			} else if (e.key === "Enter") {
				e.preventDefault();
				if (selectedIndex >= 0 && selectedIndex < filteredOptions.length) {
					handleOptionClick(filteredOptions[selectedIndex]);
				} else if (selectedIndex === filteredOptions.length) {
					handleAddNew();
				}
			}
		}
	};

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const toggleInfoIcon = () => {
		setIsPopupVisible(!isPopupVisible);
		if (onClick) {
			onClick(!isPopupVisible); // Pass the new visibility state to the onClick callback
		}
	};

	const handleAddNew = () => {
		if (onAddNew) {
			onAddNew(inputValue);
		}
		setIsDropdownVisible(false);
	};

	return (
		<div
			className={`input-select ${className}`}
			style={{ position: "relative", display: "inline-block", width: "100%" }}
		>
			<div
				className="mb-2"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
				}}
			>
				{label && <label className="form-label mb-0">{label}</label>}
				{required && <span style={{ color: "red", marginLeft: ".2rem" }}>*</span>}
				{infoIcon && (
					<i
						onClick={toggleInfoIcon}
						data-tooltip-id="my-tooltip-1"
						className="fa fa-info-circle"
						style={{
							cursor: "pointer",
							fontSize: "1.4rem",
							color: "var(--brand-color)",
							marginLeft: ".2rem",
						}}
					></i>
				)}
			</div>
			<div
				style={{
					position: "relative",
					display: "flex",
					alignItems: "center",
					border: error || isLocalValidationError ? "1px solid #f71326" : "",
					borderRadius: "6px",
				}}
				className="mb-3"
			>
				<input
					ref={propInputRef ? propInputRef : inputRef}
					type="text"
					value={inputValue}
					onChange={handleInputChange}
					onBlur={handleBlur}
					onFocus={() => setIsDropdownVisible(true)}
					onKeyDown={handleKeyDown}
					placeholder={placeholder}
					style={{
						width: "100%",
						padding: "8px",
						boxSizing: "border-box",
						borderRadius: "6px",
						paddingRight: "30px",
						background: "#FCFCFC",
					}}
					disabled={disabled}
					autoFocus={autoFocus}
					name={name}
					className="inputBG"
					onClick={handleDropDown}
					onFocusCapture={onfucusCapture}
				/>
				<i
					className="fa fa-caret-down"
					style={{
						position: "absolute",
						top: "50%",
						right: "10px",
						transform: "translateY(-50%)",
						cursor: "pointer",
					}}
					onClick={() => setIsDropdownVisible(!isDropdownVisible)}
				/>
			</div>
			{error && (
				<div
					className="error-text"
					style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}
				>
					{error}
				</div>
			)}
			{isDropdownVisible && !disabled && (
				<span className="d-block w-100" style={{ height: "150px", position: "absolute" }}>
					<ul
						className="dropdown-list"
						ref={dropdownRef}
						style={{
							position: "absolute",
							top: "0",
							left: "0",
							right: "0",
							border: "1px solid #ccc",
							borderRadius: "8px",
							maxHeight: "115px",
							minHeight: "115px",
							overflowY: "auto",
							backgroundColor: "#FCFCFC",
							listStyleType: "none",
							padding: "0",
							margin: "0",
							width: "100%",
							boxSizing: "border-box",
							zIndex: 1000,
							//                 borderBottomLeftRadius: 0,
							// borderBottomRightRadius: 0,
							borderBottomRightRadius: onAddNew ? "0" : "",
							borderBottomLeftRadius: onAddNew ? "0" : "",
						}}
					>
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option, index) => (
								<li
									key={option?.key}
									ref={index === selectedIndex ? selectedItemRef : null}
									onClick={() => handleOptionClick(option)}
									onMouseEnter={() => setSelectedIndex(index)}
									style={{
										padding: "8px",
										cursor: "pointer",
										backgroundColor: selectedIndex === index ? "#bde4ff" : "transparent",
									}}
									onMouseDown={(e) => e.preventDefault()}
								>
									{option?.value}
								</li>
							))
						) : (
							<li
								style={{
									padding: "8px",
									cursor: "default",
									textAlign: "start",
								}}
							>
								No options found
							</li>
						)}
					</ul>
					<span
						className="addNew"
						style={{
							backgroundColor: selectedIndex === filteredOptions.length ? "#bde4ff" : "#ffff",
							borderBottom: onAddNew ? "1px solid rgb(204, 204, 204)" : "0",
						}}
					>
						{onAddNew && (
							<li
								ref={selectedIndex === filteredOptions.length ? selectedItemRef : null}
								onClick={handleAddNew}
								style={{
									padding: "8px",
									cursor: "pointer",
									// backgroundColor: selectedIndex === filteredOptions.length ? '#bde4ff' : '#ffff',
									textAlign: "start",
									color: "blue",
									listStyle: "none",
								}}
								onMouseDown={(e) => e.preventDefault()}
							>
								Add New
							</li>
						)}
					</span>
				</span>
			)}
		</div>
	);
};

export default InputSelect;
