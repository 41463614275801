import React, { useRef, useState, useEffect } from "react";
import uplaod from "../../../assets/image/upload-cloud.svg";
import pdf from "../../../assets/image/pdf_icon.svg";
import close from "../../../assets/image/close.svg";
import bankIcon from "../../../assets/image/bankIcon.svg";
import "./AddBankDetails.scss";
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import { useDispatch } from "react-redux";
import { create_Vendor_document } from "../../../store/action/vendor";
import { toast } from "react-toastify";
import { isAllValuesEmpty } from "../../../utils/utility";

const AddBankDetails = ({
	isEdit,
	data,
	selectedBank,
	setBankDetails,
	onClose,
	vendorId,
	reloadTable,
	handleEditBankDetails,
}) => {
	const [files, setFiles] = useState(null);
	const inputRef = useRef();
	const dispatch = useDispatch();
	const [error, setError] = useState({});
	const [disabled, setDisabled] = useState(true); // Add state for disabling inputs

	const [formData, setFormData] = useState({
		bank_name: "",
		account_number: "",
		ifsc_code: "",
		beneficiary_name: "",
	});

	const [reaccount, setReaccount] = useState("");

	useEffect(() => {
		if (selectedBank) {
			setFormData({ ...formData, ...selectedBank });
		}
	}, [selectedBank]);

	const handleInputChange = (key, value) => {
		const updatedData = { ...formData, [key]: value };
		setFormData(updatedData);

		if (value) {
			delete error?.[key];
		}
		// console.log()
	};
	const addOrEditBankDetails = () => {
		if (isEdit) {
			handleEditBankDetails(formData);
			return;
		}

		if (vendorId) {
			dispatch(create_Vendor_document(formData, vendorId)).then((res) => {
				if (res?.success) {
					toast.success(res?.message);
					reloadTable();
					onClose();
				}
			});
		} else {
			setBankDetails([...data, formData]);
			onClose();
		}
	};

	return (
		<div className="addBank-component">
			<div className="col">
				<InputField
					label="Account Holder Name"
					placeholder="Account Holder Name"
					type="text"
					onChange={(value) => handleInputChange("beneficiary_name", value)}
					value={formData?.beneficiary_name}
					required={true}
					error={error?.beneficiary_name}
				/>
			</div>
			<div style={{ backgroundColor: "#e5e6e8", padding: "2rem" }}>
				<div className="accDetails">
					<div className="accIcon">
						<img src={bankIcon} alt="" style={{ height: "57px", borderRadius: "15px" }} />
					</div>
					<div className="accName">
						<div className="pb-2">Bank Name</div>

						<InputField
							label=""
							placeholder="ICICI bank"
							type="text"
							onChange={(value) => handleInputChange("bank_name", value)}
							value={formData?.bank_name}
							required={true}
							error={error?.bank_name}
						/>
					</div>
				</div>
				<div className="col">
					<InputField
						label="Account Number"
						placeholder="Account Number"
						type="number"
						required={true}
						onChange={(value) => handleInputChange("account_number", value)}
						value={formData?.account_number}
						error={error?.account_number}
					/>
				</div>
				{!isEdit && (
					<div className="col">
						<InputField
							label="Confirm Account Number"
							placeholder="Confirm Account Number"
							type="number"
							onChange={(value) => setReaccount(value)}
							value={reaccount}
							error={error?.reaccount}
						/>
					</div>
				)}
			</div>
			<div
				style={{
					backgroundColor: "#e5e6e8",
					padding: "2rem",
					marginTop: "1rem",
				}}
			>
				<div className="col">
					<InputField
						label="IFSC Code"
						placeholder="IFSC Code"
						type="text"
						onChange={(value) => handleInputChange("ifsc_code", value)}
						value={formData?.ifsc_code}
						error={error?.ifsc_code}
					/>
				</div>
			</div>
			<span className="">
				<Button
					type="button"
					className="btn-primary px-5 w-auto mt-3 "
					button={isEdit ? "Edit" : "Add"}
					disabled={isAllValuesEmpty(formData)}
					onClick={addOrEditBankDetails}
				></Button>
			</span>
		</div>
	);
};

export default AddBankDetails;
