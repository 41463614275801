// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bankDetailsBody {
  max-width: 53rem;
}
.bankDetailsBody .headr {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.bankDetailsBody .headr p {
  flex: 1 1;
  font-family: "PlusJakartaSans-Bold";
  font-size: 1.8rem;
}
.bankDetailsBody .headr button {
  flex: 0 1;
}
.bankDetailsBody .accDetails {
  display: flex;
  align-items: center;
}
.bankDetailsBody .accDetails .accIcon {
  flex: 0 1;
  background: #e5e6e8;
}
.bankDetailsBody .accDetails .accIcon img {
  border-radius: 10px;
}
.bankDetailsBody .accDetails .accName {
  flex: 1 1;
  margin-left: 1.5rem;
}
.bankDetailsBody .accDetails .accName div:first-child {
  font-family: "PlusJakartaSans-Bold";
}
.bankDetailsBody .accDetails .accName .numberView {
  margin-left: 6rem;
  color: var(--brand-color);
  text-decoration: underline;
}
.bankDetailsBody .accDetails .accEdit {
  flex: 0 1;
  margin: 0 1.5rem;
}
.bankDetailsBody .codeName {
  display: flex;
  margin: 1.5rem 0;
}
.bankDetailsBody .codeName span:nth-child(1) {
  flex: 1 1;
}
.bankDetailsBody .codeName span:nth-child(2) {
  flex: 2 1;
  font-family: "PlusJakartaSans-Bold";
}`, "",{"version":3,"sources":["webpack://./src/pages/vendors/BankDetails/BankDetails.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACR;AACQ;EACI,SAAA;EACA,mCAAA;EACA,iBAAA;AACZ;AAEQ;EACI,SAAA;AAAZ;AAII;EACI,aAAA;EACA,mBAAA;AAFR;AAIQ;EACI,SAAA;EACA,mBAAA;AAFZ;AAIY;EACI,mBAAA;AAFhB;AAMQ;EACI,SAAA;EACA,mBAAA;AAJZ;AAMY;EACI,mCAAA;AAJhB;AAOY;EACI,iBAAA;EACA,yBAAA;EAEA,0BAAA;AANhB;AAUQ;EACI,SAAA;EACA,gBAAA;AARZ;AAYI;EACI,aAAA;EACA,gBAAA;AAVR;AAYQ;EACI,SAAA;AAVZ;AAaQ;EACI,SAAA;EACA,mCAAA;AAXZ","sourcesContent":[".bankDetailsBody {\n    max-width: 53rem;\n\n    .headr {\n        display: flex;\n        align-items: center;\n        margin-bottom: 1rem;\n\n        p {\n            flex: 1;\n            font-family: 'PlusJakartaSans-Bold';\n            font-size: 1.8rem;\n        }\n\n        button {\n            flex: 0;\n        }\n    }\n\n    .accDetails {\n        display: flex;\n        align-items: center;\n\n        .accIcon {\n            flex: 0;\n            background: #e5e6e8;\n\n            img {\n                border-radius: 10px;\n            }\n        }\n\n        .accName {\n            flex: 1;\n            margin-left: 1.5rem;\n\n            div:first-child {\n                font-family: 'PlusJakartaSans-Bold';\n            }\n\n            .numberView {\n                margin-left: 6rem;\n                color: var(--brand-color);\n                ;\n                text-decoration: underline;\n            }\n        }\n\n        .accEdit {\n            flex: 0;\n            margin: 0 1.5rem;\n        }\n    }\n\n    .codeName {\n        display: flex;\n        margin: 1.5rem 0;\n\n        span:nth-child(1) {\n            flex: 1;\n        }\n\n        span:nth-child(2) {\n            flex: 2;\n            font-family: 'PlusJakartaSans-Bold';\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
