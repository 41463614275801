import React, { useRef, useState } from "react";
import upload from "../../../assets/image/upload-cloud.svg";
import pdf from "../../../assets/image/pdf_icon.svg";
import close from "../../../assets/image/close.svg";
import "./uploadDocument.scss";
import { toast } from "react-toastify";

const UploadDocuments = ({ setDocs, docName }) => {
	// console.log('setDocs',setDocs)
	const [files, setFiles] = useState([]);
	const inputRef = useRef();

	const validFileTypes = ["image/png", "image/jpeg", "application/pdf"];

	const handleFileValidation = (fileList) => {
		const validFiles = [];
		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i];
			if (validFileTypes.includes(file.type)) {
				validFiles.push(file);
			} else {
				toast(`Invalid file type: ${file.name}`, "error");
			}
		}
		return validFiles;
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		const validatedFiles = handleFileValidation(droppedFiles);
		console.log("validatedFiles1", validatedFiles);
		setFiles(validatedFiles);
		setDocs(validatedFiles[0]);
	};

	const handleFileChange = (event) => {
		const selectedFiles = event.target.files;
		const validatedFiles = handleFileValidation(selectedFiles);
		console.log("validatedFiles2", validatedFiles);
		setFiles(validatedFiles);
		setDocs(validatedFiles[0]);
	};

	return (
		<div className="image-upload-component">
			<div className="" onDragOver={handleDragOver} onDrop={handleDrop}>
				<input
					type="file"
					multiple
					onChange={handleFileChange}
					hidden
					accept="image/png, image/jpeg, application/pdf"
					ref={inputRef}
				/>
				<button
					className="btn btn-lg btn-primary font-14 mt-0 px-4"
					onClick={() => inputRef.current.click()}
				>
					{docName ? "Re-Upload Document" : "Upload Document"}
				</button>
			</div>
		</div>
	);
};

export default UploadDocuments;
