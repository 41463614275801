import React from "react";
import pdf from "../../../assets/image/pdf_icon.svg";
import close from "../../../assets/image/close.svg";

const ViewDocs = ({ docName }) => {
  return (
    <div className="file_list mt-2">
      {docName && (
        <div>
          <div className="file_name">
            <img
              src={pdf}
              title="shree_shyam_departmentstore.pdf"
              alt=""
              height={"20px"}
              style={{ marginRight: "1rem" }}
            />{" "}
            <span title="shree_shyam_departmentstore.pdf">{docName}</span>
          </div>
          {/* <img src={close} alt="" height={"16px"} className="cursor-pointer" /> */}
        </div>
      )}
    </div>
  );
};

export default ViewDocs;
