 

import { VENDOR_LIST_DONE } from "../../action/vendor";

const initState = {
    data: [],
    error: null,
    status: null,
    currentlogedInuser: null,
};

export const vendorReducer = (state = initState, action) => {
    switch (action.type) {
        case VENDOR_LIST_DONE:
            return {
                ...state,
                vendorList: {
                    data: action?.payload?.data?.data,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
            // case PRODUCT_BATCH_LIST_GET_DONE:
            // return {
            //     ...state,
            //     data: {
            //         data: action?.payload?.data?.data,
            //         status: action?.payload?.data?.status,
            //         error: null,
            //     },
            // };
        default:
            return state;
    }
};
