import { constant } from "./constant";

export const ObjectCreate = ({ data, key, value }) => {
	// console.log('data of batch ', data)
	return data?.map((item) => {
		return {
			["key"]: item[key],
			["value"]: `${item[value]}`,
		};
	});
};

export const getFormattedDateTime = () => {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, "0");
	const day = String(now.getDate()).padStart(2, "0");
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const emailIsValid = (email) => {
	return constant.emailRegex.test(email);
};

export const validatePANCard = (pan) => {
	return constant.panCardRegex.test(pan);
};

export const validateGSTNumber = (gst) => {
	return constant.gstRegex.test(gst);
};

export const validDrugLicenseNumber = (drugNumber) => {
	return constant.drugLicenseRegex.test(drugNumber);
};

const isEmpty = (value) => {
	return (
		value === null ||
		value === undefined ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === "object" && Object.keys(value).length === 0)
	);
};

export const isAllValuesEmpty = (obj) => {
	return Object.values(obj).every(isEmpty);
};
