import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";

const InputField = ({
  value,
  label,
  placeholder,
  type,
  onChange,
  error,
  required,
  name,
  autoFocus,
  autoComplete,
  onKeyPress,
  infoIcon,
  showPasswordIcon,
  toolTip,
  disabled,
  onClick,
  ...rest
}) => {
  const location = useLocation();
  const pathName = location?.pathname;
  const inputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  const toggleInfoIcon = () => {
    setIsPopupVisible(!isPopupVisible);
    if (onClick) {
      onClick(!isPopupVisible); // Pass the new visibility state to the onClick callback
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-group position-relative">
      {label && (
        <label className="form-label" htmlFor={`input-field-${name}`}>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      {infoIcon && (
        <i
          data-tooltip-id="my-tooltip-1"
          onClick={toggleInfoIcon}
          className="fa fa-info-circle"
          style={{
            cursor: "pointer",
            Size: "1.4rem",
            color: "var(--brand-color)",
            marginLeft: ".2rem",
          }}
        ></i>
      )}

      <div
        style={{
          border: error ? "1px solid #f71326" : "",
          borderRadius: "6px",
          height: "43px",
        }}
        className="mb-3"
      >
        <input
          value={value}
          className={`form-control mb-lg-4 mb-md-3 mb-sm-3 mb-3 inpuBG ${
            error ? "border-red" : ""
          } focus`}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={handleChange}
          name={name}
          ref={inputRef}
          id={`input-field-${name}`}
          onKeyPress={onKeyPress}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          disabled={disabled}
          {...rest}
        />
      </div>
      {error && (
        <div
          className="error-text"
          style={{ color: "red", fontSize: "12px", marginBottom: "10px" }}
        >
          {error}
        </div>
      )}
      {showPasswordIcon && (
        <i
          className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
          onClick={togglePasswordVisibility}
          style={{
            position: "absolute",
            right: "1rem",
            top: "3.9rem",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
};

InputField.defaultProps = {
  label: "",
  placeholder: "",
  type: "text",
  error: "",
  required: false,
  autoFocus: false,
  autoComplete: "on",
};

export default InputField;
