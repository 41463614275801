import React, { useState } from "react";
import InputField from "../../component/form/FormInput";
import { VendorCurrency, VendorPayTerm, VendorTds } from "../../utils/dropsdown";
import InputSelect from "../../component/inputSelect/InputSelect";
import Modal from "../../component/modal/Modal";
import UploadDocuments from "./uploadDocument/index";
import ContactPersonTable from "./ContactPerson/ContactPersonTable";
import AddContactPerson from "./ContactPerson/AddContactPerson";

const ContactPerson = ({ vendorContact, setVendorContact, reloadTable, vendorId }) => {
	const [open, setOpen] = useState(false);
	return (
		<div>
			{vendorContact?.length > 0 && (
				<ContactPersonTable
					className="max-w-1297"
					vendorId={vendorId}
					tableData={vendorContact}
					setTableData={setVendorContact}
				/>
			)}

			<div className="add-contactv" onClick={() => setOpen(true)}>
				<i className="fa fa-plus"></i>
				<span>Add New Contact</span>
			</div>

			<div>
				{open && (
					<div>
						<Modal
							className="add-contact-modal"
							isOpen={open}
							onClose={() => setOpen(false)}
							title="Add Contact Person"
							cancelButton={true}
						>
							<AddContactPerson
								isOpen={open}
								onClose={() => setOpen(false)}
								tableData={vendorContact}
								setTableData={setVendorContact}
								reloadTable={reloadTable}
								vendorId={vendorId}
							/>
						</Modal>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactPerson;
