import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "./Layout.scss";
import UnderCunstruction from "../pages/underCunstruction";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div>
      <div>
        <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className="d-flex">
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}  />
          <div className="layout-wrapper">
            {/* <UnderCunstruction prop = {'Page'}/> */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
